import {
	FeatureStateSymbol,
	CompsLifeCycleSym,
	PageFeatureConfigSymbol,
	ReducedMotionSymbol,
} from '@wix/thunderbolt-symbols'
import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import { ScreenInFactory } from './types'
import { Animations } from 'feature-animations'
import { ScreenInManager } from './ScreenInManager/ScreenInManager'
import { name } from './symbols'
import screenIn from './screenIn'
import _ from 'lodash'

const SCREEN_IN_CALLBACK = 'screenInCallback'

const screenInFactory: ScreenInFactory = (
	featureConfig,
	featureState,
	compsLifeCycle,
	reducedMotion,
	animationsProvider
) => ({
	pageWillMount: async () => {
		const { compIdToActions: actions, compIdToRotations: rotations } = featureConfig
		if (!animationsProvider) {
			return
		}
		const animationsManager = await animationsProvider.getInstance()
		const screenInManager =
			(featureState.get() && featureState.get().screenInManager) || new ScreenInManager(animationsManager)
		featureState.update((state) => ({ ...state, screenInManager }))

		if (reducedMotion) {
			Object.keys(actions).forEach((compId) => screenInManager.unhideComponent(compId))
			return
		}

		screenInManager.init(actions)
		const animations = screenIn({ manager: screenInManager })

		const compIds = _.keys(actions)
		compsLifeCycle.registerToCompLifeCycle(
			compIds,
			SCREEN_IN_CALLBACK,
			(compId: string, displayedId: string, dom: HTMLElement) => {
				let compAnimation = actions[compId]

				if (displayedId !== compId) {
					const repeaterTemplateData = JSON.parse(JSON.stringify(compAnimation))
					repeaterTemplateData[0].targetId = displayedId
					compAnimation = repeaterTemplateData
				}

				screenInManager.addDefinition({ [displayedId]: compAnimation }, dom, rotations[compId])
				animations.start(displayedId, dom, compAnimation[0])
			}
		)
	},
	pageWillUnmount: async () => {
		if (!reducedMotion) {
			const compIds = _.keys(featureConfig.compIdToActions)
			compsLifeCycle.unregisterToCompLifeCycle(compIds, SCREEN_IN_CALLBACK)
		}
	},
})

export const ScreenIn = withDependencies(
	[
		named(PageFeatureConfigSymbol, name),
		named(FeatureStateSymbol, name),
		CompsLifeCycleSym,
		ReducedMotionSymbol,
		optional(Animations),
	],
	screenInFactory
)
